<template>
  <div class="terms">
    <h1 class="h2 t-1">Terms of Service</h1>
    <hr class="line">
    <h2 class="h4 t-2">This is our updated Terms of Service, taking effect on November 9, 2021.</h2>
    <div class="p-1">
      <div>These Terms govern your access to and use of the products and services we provide through or for https://roadhub.design/ (collectively, “Services”).</div>
      <br>
      <div>Please read these Terms carefully before accessing or using our Services. By accessing or using any part of our Services, you agree to be bound by all of the Terms and all other operating rules, policies, and procedures that we may publish via the Services from time to time (collectively, the “Agreement”). You also agree that we may automatically change, update, or add on to our Services, and this Agreement will apply to any changes.</div>
      <br>
      <div class="h4">1. Who’s Who</div>
      <br>
      <div>“You” means any individual or entity using our Services. If you use our Services on behalf of another person or entity, you represent and warrant that you’re authorized to accept the Agreement on that person’s or entity’s behalf, that by using using our Services you’re accepting the Agreement on behalf of that person or entity, and that if you, or that person or entity, violates the Agreement, you and that person or entity agree to be responsible to us.</div>
      <br>
      <div class="h4">2. Responsibility of Visitors</div>
      <br>
      <div>We haven’t reviewed, and can’t review, all of the content (like text, photo, video, audio, code, computer software, items for sale, and other materials) posted to or made available through our Services by users or anyone else (“Content”) or on websites that link to, or are linked from, our Services. We’re not responsible for any use or effects of Content or third-party websites. So, for example:</div>
      <br>
      <div>⦁ We don’t have any control over third-party websites.</div>
      <br>
      <div>⦁ A link to or from one of our Services does not represent or imply that we endorse any third-party website.</div>
      <br>
      <div>⦁ We don’t endorse any Content or represent that Content is accurate, useful, or not harmful. Content could be offensive, indecent, or objectionable; include technical inaccuracies, typographical mistakes, or other errors; or violate or infringe the privacy, publicity rights, intellectual property rights, or other proprietary rights of third parties.</div>
      <br>
      <div>⦁ You’re fully responsible for the Content available on your website, and any harm resulting from that Content. It’s your responsibility to ensure that your website’s Content abides by applicable laws and by the Agreement.</div>
      <br>
      <div>⦁ We aren’t responsible for any harm resulting from anyone’s access, use, purchase, or downloading of Content, or for any harm resulting from third-party websites. You’re responsible for taking the necessary precautions to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content.</div>
      <br>
      <div>⦁ Any Content that’s for sale through any of our Services is the seller’s sole responsibility, so you must look solely to the seller for any damages that result from your purchase or use of Content.</div>
      <br>
      <div>⦁ We are not a party to, and will have no responsibility or liability for, any communications, transactions, interactions, or disputes between you and the provider of any Content.</div>
      <br>
      <div>⦁ Please note that additional third-party terms and conditions may apply to Content you download, copy, purchase, or use.</div>
      <br>
      <div class="h4">3. Copyright Infringement and DMCA Policy</div>
      <br>
      <div>As we ask others to respect our intellectual property rights, we respect the intellectual property rights of others.</div>
      <br>
      <div>The Agreement doesn’t transfer any Roadhub or third-party intellectual property to you, and all right, title, and interest in and to such property remains (as between Roadhub and you) solely with Roadhub and all other trademarks, service marks, graphics, and logos used in connection with our websites or Services are trademarks or registered trademarks of Roadhub. Other trademarks, service marks, graphics, and logos used in connection with our Services may be the trademarks of other third parties. Using our Services doesn’t grant you any right or license to reproduce or otherwise use any Roadhub or third-party trademarks.</div>
      <br>
      <div class="h4">4. Third-Party Services</div>
      <br>
      <div>While using the Services, you may enable, use, or purchase services, products, software, embeds, or applications developed by a third party or yourself (“Third-Party Services”).</div>
      <br>
      <div>If you use any Third-Party Services, you understand that:</div>
      <br>
      <div>⦁ Third-Party Services aren’t vetted, endorsed, or controlled by Roadhub.</div>
      <br>
      <div>⦁ Any use of a Third-Party Service is at your own risk, and we won’t be responsible or liable to anyone for Third-Party Services.</div>
      <br>
      <div>⦁ Your use is solely between you and the respective third party (“Third Party”) and is governed by the Third Party’s terms and policies.</div>
      <br>
      <div>⦁ Some Third-Party Services may request or require access to your data — or to your visitors’ or customers’ data — through things like pixels or cookies. If you use the Third-Party Service or grant them access, the data will be handled in accordance with the Third Party’s privacy policy and practices, which you should carefully review before you use any Third-Party Services. Third-Party Services may not work appropriately with our Services and we may not be able to provide support for issues caused by any Third-Party Services.</div>
      <br>
      <div>⦁ If you have questions or concerns about how a Third-Party Service operates or need support, contact the Third Party directly.</div>
      <br>
      <div>In rare cases we may at our discretion, suspend, disable, or remove Third-Party Services from our website.</div>
      <br>
      <div class="h4">5. Changes</div>
      <br>
      <div>We may update, change, or discontinue any aspect of our Services at any time. Since we’re constantly updating our Services, we sometimes have to change the legal terms under which they’re offered. The Agreement may only be modified by a written amendment signed by an authorized executive Roadhub, or if Roadhub posts a revised version. We’ll let you know when there are changes: we’ll post them here and update the “Last Updated” date, and we may also post on one of our blogs or send you an email or other communication before the changes become effective. Your continued use of our Services after the new terms take effect will be subject to the new terms, so if you disagree with the changes in the new terms, you should stop using our Services. To the extent you have an existing subscription, you may be eligible for a refund.</div>
      <br>
      <div class="h4">6. Disclaimers</div>
      <br>
      <div>Our Services are provided “as is.” by Roadhub. We hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Roadhub does not make any warranty that our Services will be error free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, our Services at your own discretion and risk.</div>
      <br>
      <div class="h4">7. Jurisdiction and Applicable Law</div>
      <br>
      <div>Except to the extent any applicable law provides otherwise, the Agreement and any access to or use of our Services will be governed by the laws of Israel, excluding its conflict of law provisions. The proper venue for any disputes arising out of or relating to the Agreement and any access to or use of our Services that are not otherwise subject to arbitration (as indicated below) will be the state and federal courts located in Israel.</div>
      <br>
      <div class="h4">8. Indemnification</div>
      <br>
      <div>You agree to indemnify and hold harmless Roadhub from and against any and all losses, liabilities, demands, damages, costs, claims, and expenses, including attorneys’ fees, arising out of or related to your use of our Services, including but not limited to your violation of the Agreement or any agreement with a provider of third-party services used in connection with the Services through your or another user’s website.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsContent1'
}
</script>

<style lang="scss" scoped>
.terms {
  @include grid;
  margin-top: 174px;
  margin-bottom: 128px;
  @include laptop {
    margin-top: 144px;
    margin-bottom: 96px;
  }
  @include tablet {
    margin-top: 128px;
    margin-bottom: 64px;
  }
  .t-1 {
    grid-column: 2 / 14;
    grid-row: 1 / 2;
    margin-bottom: 62px;
    @include tablet {
      grid-column: 2 / 8;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .line {
    grid-column: 2 / 14;
    grid-row: 2 / 3;
    margin-bottom: 32px;
    @include tablet {
      grid-column: 2 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      margin-bottom: 26px;
    }
  }
  .t-2 {
    grid-column: 2 / 7;
    grid-row: 3 / 4;
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .p-1 {
    grid-column: 8 / 13;
    grid-row: 3 / 4;
    @include tablet {
      margin-top: 32px;
      grid-column: 2 / 8;
      grid-row: 5 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
}

</style>
