<template>
  <div class="terms page">
    <TermsContent1/>
    <NavFooter/>
  </div>
</template>

<script>
import TermsContent1 from '../components/TermsContent1'
import NavFooter from '../components/NavFooter'

export default {
  name: 'Terms',
  components: {
    TermsContent1,
    NavFooter
  }
}
</script>

<style lang="scss" scoped>

</style>
